body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-neutral-100
}

code {
  font-family: 'Montserrat', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

h1,h2,h3,h4{
  color: rgb(82, 82, 82);
}
.widget-container:hover{
  transition: ease 200ms;
  border: 1px solid rgb(3, 209, 3);
  @apply bg-slate-200
}
.navgation:hover,
.navgation:active,
.navgation:focus{
border-bottom: 2px solid white;
}

.load {
  width: 2.5em;
  height: 3em;
  border: 3px solid transparent;
  border-top-color: #04ab05;
  border-bottom-color: #fadb0d;
  border-radius: 50%;
  animation: spin-stretch 2s ease infinite;
}

@keyframes spin-stretch {
  50% {
    transform: rotate(360deg) scale(0.4, 0.33);
    border-width: 8px;
  }
  100% {
    transform: rotate(720deg) scale(1, 1);
    border-width: 3px;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
}

.dots {
  width: 3.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.dots div {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #ffff;
  animation: fade 0.8s ease-in-out alternate infinite;
}

.dots div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.dots div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

